
import React from 'react';
import {Link, Routes, Route,} from 'react-router-dom'

import Home from './components/Home.js';
import Composers from './components/Composers.js';
import Work from './components/Work.js';
import Contact from './components/Contact.js';
// import Navbar from "./components/navigation/NavBar.jsx";

import miniLogo from './img/trill-crazy.png';

import './styles/style.css';


function App() {
  return (
    
    // NAV BAR

    <div>
      {/* <Navbar /> */}
      <nav className='navbar'>      
        <div className="nav-logo">
          <Link to='/'>
            <img 
              src={ miniLogo } 
              alt="Trill Mordent" />
          </Link>
        </div>
        <ul className="nav-list">
          <Link className="list-item" to="/work">W o r k</Link>
          <Link className="list-item" to="/composers">C o m p o s e r s</Link>
          <Link className="list-item" to="/contact">C o n t a c t</Link>
        </ul>
      </nav>

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/composers" element={<Composers />} />
        <Route path="/work" element={<Work />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>    
    </div>
  );
}

export default App;
