import React from 'react';

const Composers = () => (

  <>
    <div className="media-container">
      <div className='composer-page'>
        <h2 className="page-title">Patrick Latham</h2>
        <p className='paragraph-format'>
        Patrick Latham is a keyboardist and composer based in Brooklyn, NY. His aim is to create intricate and ornate themes within the realm of neoclassicism. His stylistic marks are an attempt to embody and embolden drama with a contemporary, but timeless appeal. Having had a lifelong fascination with the piano, he began writing and composing at the age of 13 in a small desert town in southeast California. Upon moving to Seattle as a young adult, he quickly began playing in projects in the Pacific Northwest, Los Angeles and New York. His more recent years have been circling back to his original focus of piano composition, film scoring, and commercial composing. Lathams' compositions showcase his ineffable ability to blend classical and modern piano techniques into something wholly new and tragically beautiful.
        </p>
        <hr />
        <h3 className="page-title">Gordon De Los Santos</h3>
        <p className='paragraph-format'>
        Gordon De Los Santos is a musician and sound designer based in Brooklyn, NY. His  interest in music production began in grade 6 using his brother's Gemini PDM-1008 DJ mixer, sampling records and recording to cassettes. He later participated in bands, taking on multi-instrumentalist roles. His music has been featured in skateboarding videos seen on Thrasher Magazine, Transworld, and other skate related media. Between 2012-2016, Gordon worked at Google X, Google's secret project lab, where he developed the first operational sounds and alerts for their first fully autonomous vehicle. Since then, Gordon has toured, scored short films, and was recognized by NPR for his directorial projects and music videos.
        </p>
        <hr />
        <h3 className="page-title">Adam Wolcott Smith</h3>
        <p className='paragraph-format'>
        Adam Wolcott Smith is a composer, producer, arranger, and engineer residing in Brooklyn, NY. His first job as a studio engineer was at White Star Sound in Virginia and later moved to Seattle to work at the renowned London Bridge Studios. Since moving to New York in 2019, his efforts have been focused on writing and producing for film and TV and has had placements on Bravo, Showtime, ads for Canadian broadcast stations. He has written and arranged for the Czech National Symphony Orchestra and even lent his likeness to the Ellen show. Previous clients include: Dan Boeckner of Wolf Parade, The Growlers, Purple Pilgrims, Danny Elfman, Randall Dunn and Chris Keup.
        </p>
      </div>
    </div>
  </>
);

export default Composers;