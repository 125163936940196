import React from 'react';

const Work = () => (

  <>

    <div className="media-container">
    <h2 className="page-title works-page">W o r k</h2>
      <div>
        <iframe 
          src="https://player.vimeo.com/video/592256454"
          frameborder="0" 
          allow="fullscreen; picture-in-picture" 
          allowfullscreen>
        </iframe>
        <iframe 
          src="https://player.vimeo.com/video/588920349?h=4627abd027"
          frameborder="0" 
          allow="fullscreen; picture-in-picture" 
          allowfullscreen>
        </iframe>
        <iframe 
          src="https://player.vimeo.com/video/350853588?h=f971e3403c"
          frameborder="0" 
          allow="fullscreen; picture-in-picture" 
          allowfullscreen>
        </iframe>
        <iframe 
          src="https://www.youtube.com/embed/mC431EHlUSk?si=asUu4FmXwsHqa5QL"
          frameborder="0" 
          allow="fullscreen; picture-in-picture" 
          allowfullscreen>
        </iframe>
        <iframe 
          src="https://www.youtube.com/embed/SSXjg-9oToM"
          frameborder="0" 
          allow="fullscreen; picture-in-picture" 
          allowfullscreen>
        </iframe>
        <iframe 
          src="https://www.youtube.com/embed/qqrWaP5DCko"
          frameborder="0" 
          allow="fullscreen; picture-in-picture" 
          allowfullscreen>
        </iframe>
        <iframe 
          src="https://www.youtube.com/embed/BfLBzMz0DNY"
          frameborder="0" 
          allow="fullscreen; picture-in-picture" 
          allowfullscreen>
        </iframe>
        <iframe 
          src="https://player.vimeo.com/video/16722153"
          frameborder="0" 
          allow="fullscreen; picture-in-picture" 
          allowfullscreen>
        </iframe>
        <iframe 
          src="https://www.youtube.com/embed/jrQqDgazZc8?si=Kgn1-qiE6EbuR-xu"
          frameborder="0" 
          allow="fullscreen; picture-in-picture" 
          allowfullscreen>
        </iframe>
        <iframe 
          src="https://www.youtube.com/embed/AQpVVlNioCo"
          frameborder="0" 
          allow="fullscreen; picture-in-picture" 
          allowfullscreen>
        </iframe>
      </div>
    </div>
  </>

);

export default Work;