import React from 'react';
import logo from '../img/TM_blue-logo-only.jpg';

const Home = () => (
  <div>

    <div className="logo-container">
      <img
        id="logo"
        src={ logo }
        alt="mordent musical symbol"
      />
    </div>
    <div id="about" className="about-container">
    <h1>Trill Mordent</h1>
    <h2>Vintage compositions for the contemporary moving image</h2>
    <hr />
    <p>
      Trill Mordent is a collective of composers from Brooklyn, NY. Through
      collaboration and distillation of their unique voices, they create
      dynamic sound design and musical movements with a vintage appeal for TV,
      Film, Animation and Advertising.
    </p>
    
  </div>
  </div>
);

export default Home;