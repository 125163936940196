import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";

const Contact = () => (

  <>
    <div id="contact" className='media-container'>
      <div className='contact-container'>
        <h3 className="page-title works-page">Contact</h3>
          <a href="mailto:trillmordent@gmail.com"
            >trillmordant [at] gmail [dot] com</a>
        <a href="https://www.instagram.com/trillmordent"
          className="instagram social insta-icon" target="_blank">
          <FontAwesomeIcon icon={faInstagram} size="2x" />
        </a>

      </div>
  </div>

  </>
);

export default Contact;